import en from './src/locales/en.json';
import fr from './src/locales/fr.json';

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    fr
  },
  warnHtmlMessage: false,
  strictMessages: false
}));
